import React from 'react';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';
import { Alert } from 'reactstrap';

const About = () => {
  return (
    <Layout>
      <Helmet title={'Tom Beyer: About'} />
      <h1 className="pageTitle">About</h1>
      <Alert color="info">coming soon :-)</Alert>
    </Layout>
  );
};

export default About;
